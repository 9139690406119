
.navbg {
  background-color: #4051b5 !important;

}

.nav-link {
  color: aliceblue !important;
  font-weight: 600 !important;
  text-transform: uppercase;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.gradient-custom-2 {
  /* fallback for old browsers */
  background: #fccb90;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);
}

.home {
  display: inline-block;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.pname {
  font-weight: 600;
  font-size: 50px;
  color: white;
  margin-top: 200px;

}

.home h3 {
  display: flex;
  gap: 10px;
  color: white;
  font-size: 20px;
}

.home h3 span {
  color: green;
}


@media (max-width: 612px) {
  .pname {
    font-size: 24px;
  }
}

@media (max-width: 300px) {
  .pname {
    font-size: 18px;
  }
}

@media (max-width: 300px) {
  h3 {
    font-size: 15px;
  }
}

@media (min-width: 700px) {
  .card-1 {
    margin-top: 100px !important;
    
  }
}
